import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  // CardElement,
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import "./StripeModal.css";
import { removeAllProducts } from "../../actions/cartAction";
import { useDispatch } from "react-redux";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
// Load Stripe outside of component
// const stripePromise = loadStripe(
//   "pk_test_51QhIdtKDaizQw4qLRB7Lnoun0Kks33RkRIBKgiOJHEonqn6yfx4JAodnMw0NCouLtLvqkXnrus8HTmvlyNEGtcqx00mIbNCsdp"
// );

// Stripe payment form component
const CheckoutForm = ({
  totalPrice,
  orderReadyToSubmit,
  successCallBack,
  stripeErrorCallBack,
  orderId,
  onClose,
  clientSecret,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isProcessing, setIsProcessing] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements || !orderReadyToSubmit) {
      return;
    }

    setIsProcessing(true);

    try {
      const result = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `${window.location.origin}/thank-you?order_id=${orderId}`,
        },
        redirect: "if_required",
      });

      if (result.error) {
        stripeErrorCallBack(
          result.error.message || "Une erreur est survenue lors du paiement"
        );
        toast.warn(
          result.error.message || "Une erreur est survenue lors du paiement",
          {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      } else {
        // Payment succeeded /order/verify-payment
        try {
          // Create payment intent on your backend
          const response = await fetch(
            "https://api.powerbiz.fr/website_api/public/order/verify-payment",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                order_id: orderId,
              }),
            }
          );

          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          if (response.status === 200) {
            successCallBack(true);
            dispatch(removeAllProducts());
            navigate(`/thank-you?order_id=${orderId}`);
          }
        } catch (error) {
          console.error("Error fetching payment intent:", error);
        }
      }
    } catch (error) {
      console.error("Error processing payment:", error);
      stripeErrorCallBack(
        "Une erreur est survenue lors du traitement de votre paiement"
      );
      toast.warn(
        "Une erreur est survenue lors du traitement de votre paiement",
        {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="stripe-form">
      <div className="form-field">
        <label>Informations de paiement</label>
        <PaymentElement
          options={{
            layout: {
              type: "tabs",
              defaultCollapsed: false,
            },
            paymentMethodTypes: {
              link: "never", // Disable Link payment method
            },
          }}
        />
      </div>

      <div className="stripe-buttons">
        <button
          type="submit"
          disabled={!stripe || isProcessing || !orderReadyToSubmit}
          className="stripe-pay-button"
        >
          {isProcessing
            ? "Traitement en cours..."
            : `Payer ${parseFloat(totalPrice).toFixed(2)}€`}
        </button>

        <button
          type="button"
          onClick={onClose}
          className="stripe-cancel-button"
        >
          Annuler
        </button>
      </div>
    </form>
  );
};

// Modal component for Stripe payment
const StripeModal = ({
  isOpen,
  onClose,
  totalPrice,
  orderReadyToSubmit,
  successCallBack,
  stripeErrorCallBack,
  orderId,
}) => {
  const [clientSecret, setClientSecret] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPaymentIntent = async () => {
      if (!isOpen || !orderId) return;

      try {
        setLoading(true);
        // Create payment intent on your backend
        const response = await fetch(
          "https://api.powerbiz.fr/website_api/public/create-payment-intent",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              amount: parseFloat(totalPrice).toFixed(2),
              order_id: orderId,
              currency: "eur",
            }),
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        setClientSecret(data.clientSecret);
      } catch (error) {
        console.error("Error fetching payment intent:", error);
        setError("Impossible d'initialiser le paiement. Veuillez réessayer.");
        if (stripeErrorCallBack) {
          stripeErrorCallBack("Impossible d'initialiser le paiement.");
        }
      } finally {
        setLoading(false);
      }
    };

    fetchPaymentIntent();
  }, [isOpen, orderId, totalPrice, stripeErrorCallBack]);

  if (!isOpen) return null;

  return (
    <div className="stripe-modal-overlay">
      <div className="stripe-modal">
        <div className="stripe-modal-header">
          <h3>Paiement sécurisé</h3>
          <button onClick={onClose} className="stripe-close-button">
            ×
          </button>
        </div>
        <div className="stripe-modal-content">
          {loading ? (
            <div className="loading-indicator">Chargement...</div>
          ) : error ? (
            <div className="error-message">{error}</div>
          ) : clientSecret ? (
            <Elements
              stripe={stripePromise}
              options={{
                clientSecret,
                appearance: {
                  theme: "stripe",
                },
              }}
            >
              <CheckoutForm
                totalPrice={totalPrice}
                orderReadyToSubmit={orderReadyToSubmit}
                successCallBack={successCallBack}
                stripeErrorCallBack={stripeErrorCallBack}
                orderId={orderId}
                onClose={onClose}
                clientSecret={clientSecret}
              />
            </Elements>
          ) : (
            <div className="error-message">
              Impossible d'initialiser le paiement. Veuillez réessayer.
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

// Main Stripe Provider component
const StpProvider = ({
  totalPrice,
  orderReadyToSubmit,
  successCallBack,
  stripeErrorCallBack,
  orderId,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    // If orderId is available, open the modal
    if (orderId) {
      setIsModalOpen(true);
    }
  }, [orderId]);

  // const handleOpenModal = () => {
  //   setIsModalOpen(true);
  // };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <StripeModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        totalPrice={totalPrice}
        orderReadyToSubmit={orderReadyToSubmit}
        successCallBack={successCallBack}
        stripeErrorCallBack={stripeErrorCallBack}
        orderId={orderId}
      />
    </>
  );
};

export default StpProvider;
